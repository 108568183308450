<template>
  <q-responsive :ratio="16 / 9" style="width: 700px; max-width: 80vw">
    <video
      v-if="src"
      class="video-js"
      ref="videoPlayer"
      playsinline
      style="width: 700px; max-width: 80vw"
      :type="type"
    ></video>
  </q-responsive>
</template>

<script setup>
import { nextTick, onMounted, ref } from "vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";

defineOptions({ name: "VideoComponent" });

const emits = defineEmits(["ended", "loaded", "updated"]);

const props = defineProps({
  background: { type: Boolean, default: false },
  fluid: { type: Boolean, default: false },
  loop: { type: Boolean, default: true },
  src: {
    type: String,
    default:
      "https://jpi-sizzle-db-cloudformation-stack-source71e471f1-3k1wpecklum3.s3.amazonaws.com/netflix-new-logo-animation-2019.mp4",
  },
  type: { type: String, default: "video/mp4" },
});

let player = null;
const videoPlayer = ref(null);

onMounted(async () => {
  await nextTick();
  player = videojs(videoPlayer.value, {
    autoplay: true,
    controls: true,
    fluid: props.fluid,
    loop: props.loop,
    muted: false,
    preload: "auto",
    useDevicePixelRatio: true,
  });
  player.src({ src: props.src });
  player.poster;
  player.load();
  player.on("ended", () => {
    emits("ended", player);
  });
  player.on("loadeddata", () => {
    emits("loaded", player);
  });
  player.on("timeupdate", () => {
    emits("updated", player);
  });
});
</script>
