<template>
  <q-page class="flex flex-center">
    <!-- <img
      alt="Quasar logo"
      src="~assets/quasar-logo-vertical.svg"
      style="width: 200px; height: 200px"
    /> -->
    <letter-component />
  </q-page>
</template>

<script setup>
import { watch } from "vue";
import { storeToRefs } from "pinia";
import { Dialog } from "quasar";
import { useAppStore } from "src/stores/app";
import DialogComponent from "src/components/dialog/DialogComponent.vue";
import LetterComponent from "src/components/letter/LetterComponent.vue";

defineOptions({ name: "IndexPage" });

const appStore = useAppStore();
const { app, dialogDate, project } = storeToRefs(appStore);

watch(dialogDate, () => {
  Dialog.create({
    component: DialogComponent,
    componentProps: {
      data: project.value,
    },
  });
});

console.log(app.value.coverLetter.content);
</script>
