<template>
  <div class="window-height window-width relative-position">
    <q-scroll-area class="absolute-full" :thumb-style="thumbStyle" visible>
      <div class="q-mx-auto q-pt-xl q-px-xl" style="max-width: 920px">
        <!-- <img alt="Shinola" src="~assets/shinola.svg" style="width: 200px" /> -->
        <div>
          <template
            v-for="content in app.coverLetter.content"
            :key="content._key"
          >
            <portable-text
              v-if="content._type === 'bodyBlock'"
              :value="content.body"
              :components="customComponents"
            />
          </template>
        </div>
        <q-btn
          class="q-mb-xl"
          color="primary"
          download
          :href="pdfLink"
          label="Download Resume"
          square
          target="_blank"
          text-color="dark"
          unelevated
        />
      </div>
    </q-scroll-area>
    <div class="fade-effect" />
  </div>
</template>

<script setup>
import { h, computed } from "vue";
import { storeToRefs } from "pinia";
import { QIcon, useQuasar } from "quasar";
import {
  sharpOpenInNew,
  sharpOpenInBrowser,
} from "@quasar/extras/material-icons-sharp";
import { useAppStore } from "src/stores/app";
import { PortableText } from "@portabletext/vue";

defineOptions({ name: "LetterComponent" });

const $q = useQuasar();
const appStore = useAppStore();
const { app } = storeToRefs(appStore);

const pdfLink = "/ChrisQuezada-CV.pdf";

const linkClasses = "custom-link q-pt-none";
const thumbStyle = {
  borderRadius: "0px",
};

const handleClick = (value) => {
  appStore.setDialog(value);
};

const customComponents = {
  marks: {
    link: ({ value }, { slots }) => {
      const target = value?.url?.startsWith("http") ? "_blank" : undefined;

      if (value.banner?.length) {
        return h(
          "span",
          {
            class: linkClasses,
            onClick: () => {
              handleClick(value);
            },
          },
          [
            slots.default?.(),
            h(QIcon, {
              name: sharpOpenInBrowser,
              size: "16px",
            }),
          ]
        );
      } else if (value.url) {
        return h(
          "a",
          {
            href: value.url,
            target,
            class: linkClasses,
            rel: target === "_blank" ? "noopener" : undefined,
          },
          [slots.default?.(), h(QIcon, { name: sharpOpenInNew, size: "16px" })]
        );
      }
    },
  },
  block: {
    h4: (_, { slots }) => {
      const h4Class = computed(() =>
        $q.screen.lt.md ? "text-body1" : "text-h6"
      );
      return h("h4", { class: h4Class.value }, slots.default?.());
    },
  },
};
</script>

<style>
.custom-link {
  text-decoration: underline;
  transition: background-color 0.3s, color 0.3s;
}

.custom-link:visited {
  color: var(--q-dark);
  text-decoration: underline;
  transition: background-color 0.3s, color 0.3s;
}

.custom-link:hover {
  background-color: var(--q-primary);
  color: white;
  cursor: pointer;
}

.text-h6 {
  font-weight: 100;
}
</style>
