<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card
      class="transparent row"
      flat
      square
      style="width: 700px; max-width: 80vw"
    >
      <div v-for="media in data.media" :key="media._key">
        <template v-if="media.type === 'image'">
          <image-component :media="media" />
        </template>
        <template v-else>
          <video-component :src="media.videoUrl" />
        </template>
      </div>
    </q-card>
    <div class="absolute-top-right q-ma-md" style="z-index: 1">
      <div class="q-ma-lg">
        <q-btn
          dense
          color="primary"
          :icon="symSharpClose"
          :size="$q.screen.gt.sm ? 'md' : 'sm'"
          square
          text-color="dark"
          unelevated
          @click="onOKClick()"
        />
      </div>
    </div>
  </q-dialog>
</template>

<script setup>
import { useDialogPluginComponent } from "quasar";
import { symSharpClose } from "@quasar/extras/material-symbols-sharp";
import ImageComponent from "src/components/image/ImageComponent.vue";
import VideoComponent from "src/components/video/VideoComponent.vue";

defineOptions({ name: "DialogComponent" });

const props = defineProps({
  data: { type: Object, default: () => {} },
});

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const isComponent = (type) => {
  switch (type) {
    case "image":
      return ImageComponent;
    default:
      return VideoComponent;
  }
};

function onOKClick() {
  onDialogOK();
}
</script>
